import Link from 'next/link'
import styled from 'styled-components'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import LargeCard from '../LargeCard'
import { textLinkWithHover } from '../../styles/textLink'

function CourseCards( { sectionTitle = null, label = null, cards } ) {
  const title = sectionTitle?.replace( '<p>', '' ).replace( '</p>', '' )

  return (
    <Container>
      <Stack 
        between={ 3 }
        bottom={ 4 }
      >
        { title && <StyledSectionTitle dangerouslySetInnerHTML={ { __html: title } } /> }
        <div>
          { label && (
            <StyledLabel>
              { label }
              <Link href="/training">
                <a>View all</a>
              </Link>
            </StyledLabel>
          ) }
          <StyledCourseCardsGrid>
            { cards.map( card => (
              <CourseCard
                key={ card.id }
                card={ card }
                imageSizes="( max-width: 749px ) 87vw, min( 19.666rem, calc( 29vw - 2rem ) )"
              />
            ) )}
          </StyledCourseCardsGrid>
        </div>
      </Stack>
    </Container>
  )
}

function CourseCard( { card, imageSizes } ) {
  const image = card.mainImage[ 0 ]

  return (
    <LargeCard
      url={ `/training/${ card.slug }` }
      imageUrl={ image.url }
      imageAlt={ image.imageAltText }
      title={ card.title }
      introCopy={ card.introCopy }
      ctaText="Read more"
      breakpoint={ 1200 }
      imageSizes={ imageSizes }
    />
  )
}

const StyledSectionTitle = styled.h2`
  margin: 0;
  max-width: 34rem;
  font-size: ${ p => p.theme.typo.sizes.medium };

  strong {
    ${ p => p.theme.typo.generalSansSemiBold }
  }
`

const StyledLabel = styled.h3`
  margin: 0 0 1rem;
  ${ p => p.theme.typo.generalSansSemiBold }

  > a {
    margin-left: 1rem;
    ${ p => p.theme.typo.rowan }
    color: ${ p => p.theme.colors.blueGrey__deep };
    ${ textLinkWithHover }
  }
`

const StyledCourseCardsGrid = styled.div`
  margin: -1rem;

  > * {
    margin: 1rem;

    @media ( max-width: 749px ) {
      &:first-child {
        padding-top: 1rem;
      }
    }
  }

  @media ( min-width: 750px ) {
    display: grid;
    grid-template-columns: repeat( 2, 1fr );
  }
`

export default CourseCards
