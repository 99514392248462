import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import Link from './Link'
import Image from './Image'
import textLink from '../styles/textLink'

function LargeCard( {
  url,
  imageUrl,
  imageAlt,
  imageSizes = '87vw',
  title,
  introCopy,
  ctaText = null,
  color = 'black' as 'black' | 'red',
  breakpoint = null,
} ) {
  return (
    <StyledLargeCard>
      <Link href={ url }>
        <StyledLargeCardImage>
          <Image
            src={ imageUrl }
            alt={ imageAlt }
            layout="fill"
            objectFit="cover"
            sizes={ imageSizes }
          />
        </StyledLargeCardImage>
        <StyledLargeCardContent 
          $cardColor={ color }
          $breakpoint={ breakpoint }
        >
          <h4>{ title }</h4>
          <div>{ introCopy }</div>
          { ctaText && <p><span>{ ctaText }</span></p> }
        </StyledLargeCardContent>
      </Link>
    </StyledLargeCard>
  )
}

interface WithBreakpoint {
  $breakpoint?: number,
}

const StyledLargeCard = styled.div`
  position: relative;
  display: flex;

  > a {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }
`

const StyledLargeCardImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 72%;
  background: ${ p => p.theme.colors.black };
  
  img {
    transition: opacity .3s ease-out;
  }

  a:hover & {
    img {
      opacity: 0.7;
    }
  }
`

interface StyledLargeCardContent extends WithBreakpoint {
  $cardColor: 'red' | 'black',
}

const StyledLargeCardContent = styled.div<StyledLargeCardContent>`
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 1.5rem;
  top: 35%;
  width: calc( 100% - 3rem );
  max-width: 408px;
  padding: 1.2rem 1.2rem 1.5rem;
  display: flex;
  flex-direction: column;
  transition: top .3s ease-out;
  
  ${ p => p.$cardColor === 'black' && css`
    background: ${ rgba( p.theme.getColor( p.$cardColor ), 0.75 ) };
    color: ${ p.theme.colors.white };
  ` }
    
  ${ p => p.$cardColor === 'red' && css`
    background: ${ rgba( p.theme.getColor( p.$cardColor ), 0.9 ) };
    color: ${ p.theme.colors.white };
  ` }

  h4 {
    margin: 0 0 1rem;
    ${ p => p.theme.typo.generalSansSemiBold }
  }

  p {
    margin: auto 0 0;

    > span {
      display: inline-block;
      margin-top: 1rem;
      ${ p => p.theme.typo.generalSansSemiBold }
      font-size: ${ p => p.theme.typo.sizes.small };
      ${ textLink }
    }
  }

  a:hover & {
    top: 32%;
  }

  ${ p => p.$breakpoint && css`
    @media ( max-width: ${ p.$breakpoint - 1 }px ) {
      flex-grow: 1;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      width: 100%;
      max-width: none;
      background: ${ p.theme.getColor( p.$cardColor ) };

      > * {
        max-width: 25rem;
      }

      a:hover & {
        top: auto;
      }
    }
  ` }
`

export default LargeCard
