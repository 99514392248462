import styled from 'styled-components'
import ContentSection from './ContentSection'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import Link from '../Link'
import Image from '../Image'

function PeopleGrid( { sectionTitle, introCopy, people } ) {
  return (
    <Stack
      bottom={ 4 }
    >

      { ( sectionTitle || introCopy ) && (
        <ContentSection
          contentSection={ {
            sectionTitle,
            sectionContent: [introCopy],
          } }
        />
      ) }
      <Container>
        <StyledPeopleGrid>
          { people.map( person => <Person key={ person.id } person={ person } /> ) }
        </StyledPeopleGrid>
      </Container>
    </Stack>
  )
}

function Person( { person } ) {
  return (
    <StyledPerson>
      <Link href={ `/people/${ person.slug }` }>
        <StyledPersonImage>
          <Image
            src={ person.profileImage[ 0 ].url }
            alt={ person.profileImage[ 0 ].imageAltText }
            layout="fill"
            objectFit="cover"
            sizes={ `
              ( max-width: 599px ) 87vw, 
              ( max-width: 799px ) calc( 43.5vw - 2rem ), 
              calc( 29vw - 4rem )
            ` }
          />
        </StyledPersonImage>
        <h3>{ person.title }</h3>
        { person.role && <StyledRole>{ person.role }</StyledRole> }
      </Link>
    </StyledPerson>
  )
}

const breakpoint = 1080

const StyledPeopleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: -1.5rem -1rem;
  
  > * {
    margin: 1.5rem 1rem;
  }

  @media all and ( min-width: 520px ) {
    grid-template-columns: 1fr 1fr;
  }

  @media ( min-width: 800px ) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ( min-width: ${ breakpoint }px ) {
    max-width: calc( ( 3 * 286px ) + ( 6 * 1rem ) );
    padding-left: calc( 33.333% - 286px - 1.5rem );

    > * {      
      &:first-child {
        grid-column: 2;
      }
    }
  }
`

const StyledRole = styled.p`
  margin: 0;
  font-size: inherit;
`

const StyledPersonImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 91%;
  background: ${ p => p.theme.colors.black };

  img {
    transition: opacity .3s ease-out;
  }

  @media ( min-width: ${ breakpoint }px ) {
    width: 286px;
  }
`

const StyledPerson = styled.div`
  font-size: ${ p => p.theme.typo.sizes.medium };

  a {
    text-decoration: none;
    color: ${ p => p.theme.colors.black };

    &:hover {
      ${ StyledPersonImage } {
        img {
          opacity: 0.9;
        }
      }
    }
  }

  h3 {
    margin: 1rem 0 0;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: inherit;
  }
`

export default PeopleGrid
