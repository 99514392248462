import Background from '../layout/Background'
import ContentSection from './ContentSection'
import Cards from './Cards'
import CourseCards from './CourseCards'
import FeaturedInvestigation from './FeaturedInvestigation'
import StoryCards from './StoryCards'
import CallToActionGrid from './CallToActionGrid'
import Stack from '../layout/Stack'
import PeopleGrid from './PeopleGrid'
import PrintLinks, { PrintLinksProvider } from '../PrintLinks'

const doContentBlock = contentBlock => {
  if ( contentBlock.typeHandle === 'contentSection' ) {
    return (
      <ContentSection
        contentSection={ contentBlock }
      />
    )
  }
  
  if ( contentBlock.typeHandle === 'cards' ) {
    return (
      <Cards
        cards={ contentBlock.cards }
      />
    )
  }
  
  if ( contentBlock.typeHandle === 'courseCards' ) {
    return (
      <CourseCards
        sectionTitle={ contentBlock.sectionTitle }
        label={ contentBlock.label }
        cards={ contentBlock.cards }
      />
    )
  }
  
  if ( contentBlock.typeHandle === 'featuredInvestigation' ) {
    return (
      <FeaturedInvestigation
        sectionTitle={ contentBlock.sectionTitle }
        investigation={ contentBlock.investigation[ 0 ] }
      />
    )
  }
  
  if ( contentBlock.typeHandle === 'storyCards' ) {
    return (
      <StoryCards
        label={ contentBlock.label }
        stories={ contentBlock.stories }
        allowTwoCols={ (
          contentBlock.stories.length > 3
          || contentBlock.stories.length % 2 === 0
        ) }
      />
    )
  }
  
  if ( contentBlock.typeHandle === 'callToAction' ) {
    return (
      <CallToActionGrid
        callsToAction={ contentBlock.callsToAction }
      />
    )
  }
  
  if ( contentBlock.typeHandle === 'peopleGrid' ) {
    return (
      <PeopleGrid
        sectionTitle={ contentBlock.sectionTitle }
        introCopy={ contentBlock.introCopy }
        people={ contentBlock.people }
      />
    )
  }

  return null
} 

function PageContent( { pageContent, headerHasButton = false } ) {
  let currentBorderTop = null
  let currentBgColor = null
  let currentHasNewBgColor = false

  return (
    <PrintLinksProvider>
      { pageContent.map( ( contentBlock, i ) => {
        if ( contentBlock.typeHandle === 'hr' ) {
          if ( contentBlock.hrType === 'line' ) {
            currentBorderTop = 'black'
          }
          else {
            currentBgColor = contentBlock.hrType
            currentHasNewBgColor = true
          }
        
          return null
        }
        
        if ( i === 0 && headerHasButton ) {
          currentBorderTop = 'black'
        }

        const borderTop = currentBorderTop
        currentBorderTop = null
        const hasNewBgColor = currentHasNewBgColor
        currentHasNewBgColor = false

        return (
          <Background 
            key={ contentBlock.id }
            borderTop={ borderTop }
            color={ currentBgColor }
          >
            { borderTop && (
              <Stack top={ 4 }>
                { doContentBlock( contentBlock ) }
              </Stack>
            ) }
            { hasNewBgColor && (
              <Stack top={ 4 }>
                { doContentBlock( contentBlock ) }
              </Stack>
            ) }
            { !borderTop && !hasNewBgColor && doContentBlock( contentBlock ) }
          </Background>
        )
      } ) }
      <PrintLinks />
    </PrintLinksProvider>
  )
}

export default PageContent
