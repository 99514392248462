import { Fragment, useState } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { formatPostDate } from '../../lib/date'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import Image from '../Image'
import { humanList } from '../../lib/strings'
import textLink, { textLinkWithHover } from '../../styles/textLink'

const emptyMessage = 'There are no stories matching your filter selection. Please clear the filters and start again.'

function StoryCards( { label = null, stories, allowTwoCols = true } ) {
  const imgSizes = allowTwoCols
    ? '( max-width: 649px ) 87vw, ( max-width: ) calc( 43.5vw - 2rem ), min( 17.6rem, calc( 29vw - 4rem ) )'
    : '( max-width: 749px ) 87vw, min( 17.6rem, calc( 29vw - 4rem ) )'

  return (
    <Container>
      <Stack 
        between={ 3 }
        bottom={ 4 }
      >
        <div>
          { label && (
            <StyledLabel>
              <>
                { label }
                <Link href="/stories">
                  <a>View all</a>
                </Link>
              </>
            </StyledLabel>
          ) }
          { stories.length > 0 && (
            <StyledStoryCardsGrid $allowTwoCols={ allowTwoCols }>
              { stories.map( card => card.mainImage.length > 0 && (
                <StoryCard
                  key={ card.id }
                  card={ card }
                  imgSizes={ imgSizes }
                />
              ) ) }
            </StyledStoryCardsGrid>
          ) }
          { stories.length === 0 && (
            <StyledEmptyMessage>
              { emptyMessage }
            </StyledEmptyMessage>
          ) }
        </div>
      </Stack>
    </Container>
  )
}

function StoryCard( { card, imgSizes } ) {
  const [isStoryLinkHovered, setIsStoryLinkHovered] = useState( false )
  const image = card.mainImage[ 0 ]

  const storyTags = [
    ...card.topic.map( tag => ( { 
      ...tag,
      href: `/stories?topic=${ tag.slug }`,
    } ) ),
    ...card.location.map( tag => ( { 
      ...tag,
      href: `/stories?location=${ tag.slug }`,
    } ) ),
  ]

  const storyAuthors = card.entryAuthor.map( author => (
    <Link
      key={ author.id }
      href={ `/stories?author=${ author.slug }` }
    >
      <a>{ author.title }</a>
    </Link>
  ) )

  return (
    <StyledStoryCard $isStoryLinkHovered={ isStoryLinkHovered }>
      <Link href={ `/stories/${ card.slug }` } passHref>
        <StyledStoryCardImage
          aria-label={ card.title }
          onMouseEnter={ () => setIsStoryLinkHovered( true ) }
          onMouseLeave={ () => setIsStoryLinkHovered( false ) }
        >
          <Image
            src={ image.url }
            alt={ image.imageAltText }
            layout="fill"
            objectFit="cover"
            sizes={ imgSizes }
          />
        </StyledStoryCardImage>
      </Link>
      <StyledStoryCardContent>
        <StyledStoryCardTags>
          { storyTags.map( ( tag, i ) => (
            <Fragment key={ tag.id }>
              { i > 0 && ', ' }
              <Link href={ tag.href }>
                <a>{ tag.title }</a>
              </Link>
            </Fragment>
          ) ) }
        </StyledStoryCardTags>
        <Link href={ `/stories/${ card.slug }` }>
          <a
            onMouseEnter={ () => setIsStoryLinkHovered( true ) }
            onMouseLeave={ () => setIsStoryLinkHovered( false ) }
          >
            <h4>{ card.title }</h4>
          </a>
        </Link>
        <StyledStoryCardMeta>
          { storyAuthors.length > 0 && (
            <StyledStoryCardAuthors>
              By { humanList( storyAuthors ) }
            </StyledStoryCardAuthors>
          ) }
          <StyledStoryCardDate>
            { formatPostDate( card.postDate ) }
          </StyledStoryCardDate>
        </StyledStoryCardMeta>
      </StyledStoryCardContent>
    </StyledStoryCard>
  )
}

const StyledLabel = styled.h3`
  margin: 0;
  padding: 0 0 1rem;
  ${ p => p.theme.typo.generalSansSemiBold }

  > a {
    margin-left: 1rem;
    ${ p => p.theme.typo.rowan }
    color: ${ p => p.theme.colors.blueGrey__deep };
    ${ textLinkWithHover }
  }
`

interface StyledStoryCardsGrid {
  $allowTwoCols: boolean,
}

const StyledStoryCardsGrid = styled.div<StyledStoryCardsGrid>`
  margin: -2rem -1rem;

  > * {
    margin: 2rem 1rem;
  }

  ${ p => p.$allowTwoCols && css`
    @media ( min-width: 650px ) {
      display: grid;
      grid-template-columns: repeat( 2, 1fr );
    }
    
    @media ( min-width: 900px ) {
      display: grid;
      grid-template-columns: repeat( 3, 1fr );
    }
  ` }
  
  ${ p => !p.$allowTwoCols && css`
    @media ( min-width: 750px ) {
      display: grid;
      grid-template-columns: repeat( 3, 1fr );
    }
  ` }
`

const StyledStoryCardImage = styled.a`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 72%;
  background: ${ p => p.theme.colors.black };

  img {
    transition:
      filter .3s ease-out,
      opacity .3s ease-out;
  }
`

const StyledStoryCardContent = styled.div`
  max-width: 25rem;
  color: ${ p => p.theme.colors.black };

  a > h4 {
    margin: 0;
    ${ p => p.theme.typo.generalSansSemiBold }
    color: ${ p => p.theme.colors.black };
    ${ textLink }
    text-decoration-color: transparent;
    transition: text-decoration-color .3s ease-out;
  }
`

const StyledStoryCardTags = styled.div`
  padding: 0.3rem 0;
  ${ p => p.theme.typo.generalSans }
  font-size: ${ p => p.theme.typo.sizes.small };
  color: ${ p => p.theme.colors.blueGrey__deep };

  a {
    display: inline-block;
    color: inherit;
    ${ textLinkWithHover }
    text-decoration-color: currentColor;
  }
`

const StyledStoryCardMeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 -0.5rem;
  padding: 0.8rem 0 0;
  ${ p => p.theme.typo.generalSans }
  font-size: ${ p => p.theme.typo.sizes.small };
  color: ${ p => p.theme.colors.black };

  > * {
    border-left: 1px solid ${ p => p.theme.colors.blueGrey__deep };
    padding: 0 0.5rem;
    margin-left: -1px;
  }
`

const StyledStoryCardAuthors = styled.span`
  a {
    display: inline-block;
    color: inherit;
    ${ textLinkWithHover }
    text-decoration-color: currentColor;
  }
`

const StyledStoryCardDate = styled.span`
  color: ${ p => p.theme.colors.blueGrey__deep };
`

interface StyledStoryCard {
  $isStoryLinkHovered: boolean,
}

const StyledStoryCard = styled.div<StyledStoryCard>`
  position: relative;

  a {
    text-decoration: none;
  }

  ${ p => p.$isStoryLinkHovered && css`
    ${ StyledStoryCardContent } h4 {
      text-decoration-color: ${ p.theme.colors.red };
    }

    ${ StyledStoryCardImage } {
      img {
        filter: grayscale( 1 );
        opacity: 0.9;
      }
    }
  ` }
`

const StyledEmptyMessage = styled.p``

export default StoryCards
