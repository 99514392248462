import styled from 'styled-components'
import Stack from '../layout/Stack'
import Container from '../layout/Container'
import { StyledArticleContent } from '../article/ArticleContent'
import { usePrintLinks } from '../PrintLinks'

function ContentSection( { contentSection } ) {
  const { printLinksRef } = usePrintLinks()

  return (
    <Stack
      bottom={ 4 }
    >
      <Container>
        <StyledContentSection ref={ printLinksRef }>
          <h2>{ contentSection.sectionTitle }</h2>
          <div>
            <StyledContent dangerouslySetInnerHTML={ { __html: contentSection.sectionContent } } />
          </div>
        </StyledContentSection>
      </Container>
    </Stack>
  )
}

const StyledContentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem -1rem;

  > * {
    box-sizing: border-box;
    padding: 0.5rem 1rem;

    &:first-child {
      flex: 1 0 33.333%;
    }
    
    &:last-child {
      flex: 1 0 66.666%;
      min-inline-size: 28rem;

      > div {
        max-width: 40rem;
      }

      @media ( max-width: 35rem ) {
        min-inline-size: 100%;
      }
    }
  }

  h2 {
    margin: 0;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.medium };
    color: ${ p => p.theme.colors.red };
  }
`

const StyledContent = styled( StyledArticleContent )`
  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .fu-c-content__large-text {
    font-size: ${ p => p.theme.typo.sizes.medium };
  }
`

export default ContentSection
