import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import Link from '../Link'
import Image from '../Image'
import extractLinkitParts from '../../lib/extractLinkitParts'
import textLink from '../../styles/textLink'

function CallToActionGrid( { callsToAction } ) {
  return (
    <StyledCallToActionGrid>
      { callsToAction.map( callToAction => (
        <CallToAction
          key={ callToAction.id }
          copy={ callToAction.ctaCopy }
          ctaText={ callToAction.ctaText }
          link={ callToAction.ctaLink?.link }
          backgroundColor={ callToAction.backgroundColor }
          backgroundImage={ callToAction.backgroundImage?.[ 0 ] }
        />
      ) ) }
    </StyledCallToActionGrid>
  )
}

function CallToAction( {
  copy, ctaText, link, backgroundColor, backgroundImage, 
} ) {
  return (
    <StyledCallToAction
      $hasBgImage={ !!backgroundImage }
      $bgColor={ backgroundColor }
    >
      { backgroundImage && (
        <StyledBgImage>
          <Image
            src={ backgroundImage.url }
            alt=""
            layout="fill"
            objectFit="cover"
            sizes="( max-width: 700px ) 87vw, 43.5vw"
          />
        </StyledBgImage>
      ) }
      <Link href={ extractLinkitParts( link ).url }>
        <div>
          <h4 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={ { 
              __html: copy.replace( '<p>', '' ).replace( '</p>', '' ),
            } }
          />
          <span>{ ctaText }</span>
        </div>
      </Link>
    </StyledCallToAction>
  )
}

const StyledCallToActionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div + div {
    margin-left: 1px;
  }

  @media ( max-width: 700px ) {
    flex-direction: column;

    > div + div {
      margin-left: 0;
      margin-top: 1px;
    }
  }
`

interface StyledCallToAction {
  $hasBgImage: boolean,
  $bgColor: 'black' | 'red' | 'grey' | 'white',
}

const StyledCallToAction = styled.div<StyledCallToAction>`
  position: relative;
  flex: 1 0;
  display: flex;
  align-items: stretch;

  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: max( 3vw, 6rem ) 0;
    background: ${ p => p.theme.colors.black };
    color: ${ p => p.theme.colors.white };
    ${ p => p.theme.typo.rowan }
    font-size: ${ p => p.theme.typo.sizes.medium };
    text-decoration: none;
    text-align: center;

    h4 {
      margin: 0 0 1em 0;

      strong {
        ${ p => p.theme.typo.generalSansSemiBold }
      }
    }

    span {
      ${ p => p.theme.typo.generalSansSemiBold }
      font-size: ${ p => p.theme.typo.sizes.small };
      ${ textLink }
      transition: text-decoration-color .3s ease-out;
    }

    > div {
      max-width: 385px;
      padding: 0 6.5vw;
    }

    &:hover {
      span {
        text-decoration-color: ${ p => p.theme.colors.white };
      }
    }

    ${ p => p.$bgColor === 'black' && css`
      background: ${ p.$hasBgImage ? rgba( p.theme.colors.black, 0.9 ) : p.theme.colors.black };
    ` }
    
    ${ p => p.$bgColor === 'red' && css`
      background: ${ p.$hasBgImage ? rgba( p.theme.colors.red, 0.9 ) : p.theme.colors.red };

      span {
        text-decoration-color: ${ p.theme.colors.black };
      }
    ` }
    
    ${ p => p.$bgColor === 'grey' && css`
      background: ${ p.$hasBgImage ? rgba( p.theme.colors.blueGrey__superlight, 0.9 ) : p.theme.colors.blueGrey__superlight };
    ` }
    
    ${ p => p.$bgColor === 'white' && css`
      background: ${ p.$hasBgImage ? rgba( p.theme.colors.white, 0.9 ) : p.theme.colors.white };

      &:hover {
        span {
          text-decoration-color: ${ p.theme.colors.black };
        }
      }
    ` }

    
  }
`

const StyledBgImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export default CallToActionGrid
