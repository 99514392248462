import styled from 'styled-components'
import { rgba } from 'polished'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import Image from '../Image'
import Link from '../Link'
import textLink from '../../styles/textLink'

function FeaturedInvestigation( { sectionTitle, investigation } ) {
  const title = sectionTitle.replace( '<p>', '' ).replace( '</p>', '' )
  const image = investigation.mainImage[ 0 ]

  return (
    <Container fullWidth>
      <Stack 
        between={ 2 }
        bottom={ 4 }
      >
        <Container>
          <StyledSectionTitle dangerouslySetInnerHTML={ { __html: title } } />
        </Container>
        <StyledFeaturedInvestigation>
          <Link href={ `/investigations/${ investigation.slug }` }>
            <StyledFeaturedInvestigationImage>
              <Image
                src={ image.url }
                alt={ image.imageAltText }
                layout="fill"
                objectFit="cover"
                sizes="87vw"
              />
            </StyledFeaturedInvestigationImage>
            <StyledFeaturedInvestigationContent>
              <Container>
                <div>
                  <StyledFeaturedInvestigationContentInner>
                    <h3>{ investigation.title }</h3>
                    <div>{ investigation.introCopy }</div>
                    <p>
                      <span>Read more</span>
                    </p>
                  </StyledFeaturedInvestigationContentInner>
                </div>
              </Container>
            </StyledFeaturedInvestigationContent>
          </Link>
        </StyledFeaturedInvestigation>
      </Stack>
    </Container>
  )
}

const breakpoint = 700

const StyledSectionTitle = styled.h2`
  margin: 0;
  max-width: 34rem;
  font-size: ${ p => p.theme.typo.sizes.medium };

  strong {
    ${ p => p.theme.typo.generalSansSemiBold }
  }
`

const StyledFeaturedInvestigationImage = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 46%;
  background: ${ p => p.theme.colors.black };
  
  img {
    transition: opacity .3s ease-out;
  }
`

const StyledFeaturedInvestigationContent = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;

  > div {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    > div {
      position: relative;
      width: 100%;
    }
  }

  @media ( min-width: ${ breakpoint }px ) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const StyledFeaturedInvestigationContentInner = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${ p => p.theme.colors.white };

  > * {
    max-width: 25rem;
  }

  h3 {
    margin: 0 0 1rem;
    ${ p => p.theme.typo.generalSansSemiBold }
    font-size: ${ p => p.theme.typo.sizes.normal };
  }

  > p {
    margin: 0;

    > span {
      display: inline-block;
      margin-top: 2rem;
      ${ p => p.theme.typo.generalSansSemiBold }
      font-size: ${ p => p.theme.typo.sizes.small };
      ${ textLink };
      text-decoration-color: ${ p => p.theme.colors.black };
    }
  }

  @media ( max-width: ${ breakpoint - 1 }px ) {
    margin-left: -6.5vw;
    margin-right: -6.5vw;
    padding: 1.2rem 6.5vw;
    background: ${ p => p.theme.colors.red };
  }

  @media ( min-width: ${ breakpoint }px ) {
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 50%;
    width: calc( 100% - 3rem );
    max-width: 408px;
    padding: 1.2rem 1.2rem 1.5rem;
    background: ${ p => rgba( p.theme.colors.red, 0.95 ) };
  }
`

const StyledFeaturedInvestigation = styled.div`
  position: relative;

  > a {
    display: block;
    text-decoration: none;

    &:hover {
      ${ StyledFeaturedInvestigationImage } {
        img {
          opacity: 0.8;
        }
      }
    }
  }
`

export default FeaturedInvestigation
