import styled from 'styled-components'
import Container from '../layout/Container'
import Stack from '../layout/Stack'
import Image from '../Image'

function Cards( { cards } ) {
  return (
    <Container>
      <Stack bottom={ 4 }>
        <StyledCardsGrid>
          { cards.map( card => (
            <Card
              key={ card.id }
              card={ card }
            />
          ) )}
        </StyledCardsGrid>
      </Stack>
    </Container>
  )
}

function Card( { card } ) {
  const image = card.cardImage?.[ 0 ]

  return (
    <StyledCard>
      { image && (
        <StyledCardImage $maxWidth={ image.width }>
          <Image
            src={ image.url }
            alt={ image.imageAltText }
            width={ image.width }
            height={ image.height }
            sizes="( max-width: 749 ) 87vw, calc( 29vw - 4rem )"
          />
        </StyledCardImage>
      ) }
      <h3>{ card.cardTitle }</h3>
      <div 
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: card.cardBody } } 
      />
    </StyledCard>
  )
}

const StyledCardsGrid = styled.div`
  margin: -1rem;

  > * {
    margin: 1rem;

    @media ( max-width: 749px ) {
      &:not( :first-child ) {
        padding-top: 1rem;
      }
    }
  }

  @media ( min-width: 750px ) {
    display: grid;
    grid-template-columns: repeat( 3, 1fr );
  }
`

const StyledCard = styled.div`
  h3 {
    margin: 1rem 0;
    ${ p => p.theme.typo.generalSansSemiBold };
    font-size: ${ p => p.theme.typo.sizes.medium };
  }
  
  > div {
    font-variation-settings: 'wght' 300;

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

interface StyledCardImage {
  $maxWidth: number,
}

const StyledCardImage = styled.div<StyledCardImage>`
  max-width: ${ p => p.$maxWidth }px;
`

export default Cards
